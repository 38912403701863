.sidebar{
    height: 100%; 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: -1;
    background-color:rgba(0,0,0,0.001);
    border-right: 1px solid rgba(0,0,0,0.1);
    width:215px; 
    align-items: center;
  }