.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toastSuccess{
  background-color: #00458B !important
}

.toastError{
  background-color: #d32f2f !important
}

.toastErrorBody > div, .toastSuccessBody > div {
    height: auto
}

.Toastify__toast{
    min-height: 50px !important
}

.Toastify__close-button{
    margin-top: 9px;
    padding: 0px 12px 0px 10px;
    font-weight: normal
}

.Toastify__toast-container{
    width: 360px !important
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
